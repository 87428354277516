import httpService from './httpService';

export default {

  getTiers: async () => {
    return httpService.get(`tiers`);
  },

  getContrats: async (tiers_code) => {
    //console.log(tiers_code);
    //return [{contrat_code: "D_24_MAGASIN_EUREDEN", contrat_libelle: "MAGASINS EUREDEN"}];
    return httpService.get(`tiers/${tiers_code}/contrats?with-perime=false`);
  },

  getEtiquette: async (tiers_code, commandeId) => {
    return httpService.get(`tiers/${tiers_code}/commandes/${commandeId}/etiquette`);
  },

  getDepotPdf: async () => {
    return httpService.get('depot_pdf');
  },

  getChronopostInfos: async (tiers_code, commandeId) => {
    return httpService.get(`tiers/${tiers_code}/commandes/${commandeId}/chronopost`);
  },
};

