import httpService from './httpService';

export default {
  saveCommande: async (tiers_code, commande) => {
    return httpService.post(
      `tiers/${tiers_code}/commandes`,
      JSON.stringify(commande)
    );
    // console.log(tiers_code);
    // console.log(commande);
    // return {
    //   "echant_nombre": 1,
    //   "date_previs_reception": "2025-01-21 01:00:00",
    //   "contrat_code": "D_24_MAGASIN_EUREDEN",
    //   "type_analyse": 4,
    //   "etat": 1,
    //   "date_creation": "2025-01-20T15:06:33.000000Z",
    //   "id": 40052,
    //   "commande_id": "99971940052"
    // };

  },

  updateCommande: async (tiers_code, commande) => {
    return httpService.put(
      `tiers/${tiers_code}/commandes/${commande.id}`,
      JSON.stringify(commande)
    );
    // console.log(tiers_code);
    // console.log(commande);
    // return {
    //   "echant_nombre": 1,
    //   "date_previs_reception": "2025-01-21 01:00:00",
    //   "contrat_code": "D_24_MAGASIN_EUREDEN",
    //   "type_analyse": 4,
    //   "etat": 1,
    //   "date_creation": "2025-01-20T15:06:33.000000Z",
    //   "id": 40052,
    //   "commande_id": "99971940052"
    // };
  },

  deleteCommande: async (tiers_code, commande) => {
    return httpService.delete(`tiers/${tiers_code}/commandes/${commande.id}`);
  },

  saveDocument: async (tiers_code, commandeId, document) => {
    return httpService.post(
      `tiers/${tiers_code}/commandes/${commandeId}/documents`,
      JSON.stringify(document)
    );
  }
};
