<template v-if="isLogin">
  <v-container grid-list-md>
    <v-card elevation="0">
      <v-card-title
        :class="
          !$vuetify.breakpoint.md && !$vuetify.breakpoint.lg
            ? 'page-title'
            : 'page-title-lg'
        "
      >
        <span class="align-title">Planification des réceptions</span>
      </v-card-title>
      <v-card-text>
        <v-row
          :class="
            !$vuetify.breakpoint.md &&
            !$vuetify.breakpoint.lg &&
            !$vuetify.breakpoint.xl
              ? 'row-margin'
              : 'lg-row-margin'
          "
        >
          <v-col
            cols="12"
            sm="12"
            lg="3"
            md="3"
            :class="
              !$vuetify.breakpoint.md &&
              !$vuetify.breakpoint.lg &&
              !$vuetify.breakpoint.xl
                ? 'align-text'
                : ''
            "
          >
            <span class="label-text-center label-text"> Demandeur </span>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            lg="5"
            md="5"
            :class="
              !$vuetify.breakpoint.md &&
              !$vuetify.breakpoint.lg &&
              !$vuetify.breakpoint.xl
                ? 'col-pad'
                : ''
            "
          >
            <v-combobox
              v-model="selectedTiers"
              :items="tiers"
              class="list-tiers"
              item-text="tiers_nom"
              item-value="tiers_code"
              label="Liste des tiers"
              placeholder="Commencez à taper pour faire une recherche"
              no-data-text="Pas de tiers trouvés"
              dense
              filled
              outlined
              solo
              :filter="filterTiers"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title> Pas de résultats </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <template v-slot:selection="{ item }">
                <v-chip v-if="typeof item === 'object'" class="ma-2 ps-6" small>
                  {{ item.tiers_nom }} - {{ item.tiers_prenom }} ({{
                    item.tiers_code
                  }})
                </v-chip>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title>
                    {{ item.tiers_nom }} -
                    {{ item.tiers_prenom }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ item.tiers_code }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-combobox>
          </v-col>
          <v-col cols="0" sm="0" lg="4" md="4" />
        </v-row>
        <v-row
          v-show="contrats.length"
          :class="
            !$vuetify.breakpoint.md &&
            !$vuetify.breakpoint.lg &&
            !$vuetify.breakpoint.xl
              ? 'row-margin'
              : 'lg-row-margin'
          "
        >
          <v-col
            cols="12"
            sm="12"
            lg="3"
            md="3"
            :class="
              !$vuetify.breakpoint.md &&
              !$vuetify.breakpoint.lg &&
              !$vuetify.breakpoint.xl
                ? 'align-text'
                : ''
            "
          >
            <span class="label-text-center label-text"> Contrat </span>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            lg="5"
            md="5"
            :class="
              !$vuetify.breakpoint.md &&
              !$vuetify.breakpoint.lg &&
              !$vuetify.breakpoint.xl
                ? 'col-pad'
                : ''
            "
          >
            <v-select
              ref="contrat"
              v-model="commande.contrat_code"
              :items="contrats"
              :item-text="
                (item) => `( ${item.contrat_code} ) ` + item.contrat_libelle
              "
              :item-value="(item) => item.contrat_code"
              :rules="required"
              label="Liste des contrats"
              no-data-text="Pas de contrat trouvés"
              dense
              outlined
            />
          </v-col>
          <v-col cols="0" sm="0" lg="4" md="4" />
        </v-row>
        <v-row
          v-show="contrats.length"
          :class="
            !$vuetify.breakpoint.md &&
            !$vuetify.breakpoint.lg &&
            !$vuetify.breakpoint.xl
              ? 'row-margin'
              : 'lg-row-margin'
          "
        >
          <v-col
            cols="12"
            sm="12"
            lg="3"
            md="3"
            :class="
              !$vuetify.breakpoint.md &&
              !$vuetify.breakpoint.lg &&
              !$vuetify.breakpoint.xl
                ? 'align-text'
                : ''
            "
          >
            <span class="label-text-center label-text"> Type d’analyses </span>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            lg="5"
            md="5"
            :class="
              !$vuetify.breakpoint.md &&
              !$vuetify.breakpoint.lg &&
              !$vuetify.breakpoint.xl
                ? 'col-pad'
                : ''
            "
          >
            <v-select
              v-model="typeAnalyse"
              ref="typesAnalyses"
              :items="typesAnalyses"
              :hint="`${typeAnalyse.hint || ''}`"
              :item-text="(item) => item.text"
              :item-value="(item) => item.code"
              :rules="required"
              label="Types d'analyses"
              dense
              outlined
              persistent-hint
              return-object
            />
          </v-col>
          <v-col cols="0" sm="0" lg="4" md="4" />
        </v-row>
        <v-row
          v-show="
            contrats.length &&
            ($vuetify.breakpoint.md ||
              $vuetify.breakpoint.lg ||
              $vuetify.breakpoint.xl)
          "
          class="lg-row-margin"
        >
          <v-col cols="12" sm="12" lg="3" md="3">
            <span class="label-text-center label-text">
              Nombre d’échantillons à confier :
            </span>
          </v-col>
          <v-col cols="12" sm="12" lg="5" md="5">
            <v-text-field
              v-model="commande.echant_nombre"
              :value="commande.echant_nombre"
              type="number"
              :rules="nbEchRules"
              :max="100"
              :min="1"
              outlined
              @change="checkNbEch"
            />
          </v-col>
          <v-col cols="12" sm="12" lg="4" md="4" />
        </v-row>
        <v-row
          v-show="
            contrats.length &&
            !$vuetify.breakpoint.md &&
            !$vuetify.breakpoint.lg &&
            !$vuetify.breakpoint.xl
          "
          class="row-margin"
        >
          <v-col cols="12" class="align-text">
            <span class="label-text-center label-text">
              Nombre d’échantillons à confier
            </span>
          </v-col>
          <v-col cols="12" class="align-text">
            <div class="left-part">
              <div class="f-icon">
                <v-icon @click="increment()"> fas fa-plus-circle </v-icon>
              </div>
              <div class="f-icon">
                <v-icon @click="decrement()"> fas fa-minus-circle </v-icon>
              </div>
            </div>
            <div class="right-part">
              <v-text-field
                v-model="commande.echant_nombre"
                :value="commande.echant_nombre"
                type="number"
                height="100"
                :rules="nbEchRules"
                :max="100"
                :min="1"
                class="nb-ech-input"
                outlined
                @change="checkNbEch"
              />
            </div>
          </v-col>
        </v-row>
        <v-row v-show="contrats.length" class="lg-row-margin">
          <v-col
            cols="12"
            sm="12"
            lg="3"
            md="3"
            :class="
              !$vuetify.breakpoint.md &&
              !$vuetify.breakpoint.lg &&
              !$vuetify.breakpoint.xl
                ? 'align-text'
                : ''
            "
          >
            <span class="label-text-center label-text">
              Date de réception prévisionnelle
              {{ $vuetify.breakpoint.md || $vuetify.breakpoint.lg ? ':' : '' }}
            </span>
          </v-col>
          <v-col cols="12" sm="12" lg="5" md="5" class="align-text">
            <app-date
              v-model="commande.date_previs_reception"
              :initial-date="commande.date_previs_reception"
              :min-date="currentDate"
              @dateChange="checkPrevDate"
            />
          </v-col>
          <v-col cols="12" sm="12" lg="4" md="4" />
        </v-row>
        <v-row v-show="noContrat">
          <v-col cols="0" sm="0" lg="3" md="3" />
          <v-col cols="12" sm="12" lg="9" md="9">
            <span class="pas-contrat"
              >Vous n'avez pas saisi de contrat, merci de contacter
              Capinov</span
            >
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="big-screen">
        <v-row class="row-margin-action-lg">
          <v-col cols="10" sm="9" />
          <v-col cols="2" sm="3">
            <v-btn
              :disabled="
                !currentDate ||
                !selectedTiers ||
                !contrats.length ||
                !(commande.type_analyse ? true : false) ||
                !(commande.contrat_code ? true : false) ||
                !(commande.echant_nombre >= 1 && commande.echant_nombre <= 100)
              "
              color="primary"
              block
              @click="next()"
            >
              <span> Suivant </span>
              <v-icon class="btn-icon-right">
                fas fa-arrow-alt-circle-right
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-footer fixed color="white" class="small-screen">
      <v-row class="footer-row">
        <v-col cols="0" xs="0" sm="0" lg="8" md="6" />
        <v-col cols="12">
          <v-btn
            :disabled="
              !currentDate ||
              !selectedTiers ||
              !contrats.length ||
              !(commande.type_analyse ? true : false) ||
              !(commande.contrat_code ? true : false) ||
              !(commande.echant_nombre >= 1 && commande.echant_nombre <= 100)
            "
            class="primary"
            block
            @click="next()"
          >
            <span> Suivant </span>
            <v-icon class="btn-icon-right">
              fas fa-arrow-alt-circle-right
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-footer>
  </v-container>
</template>

<script>
import AppDate from '@/components/common/AppDate';
import env from '@/config/env';
import errorMixin from '@/mixins/errorMixin';
import mixins from '@/mixins/mixins.js';
import { addDays, format, isBefore, parseISO } from 'date-fns';
import { mapActions, mapMutations, mapState } from 'vuex';
import keycloakSrv from '../../services/keycloakService';
import searchSrv from '../../services/searchService';
import updateSrv from '../../services/updateService';

export default {
  name: 'planification',
  mixins: [errorMixin, mixins],
  components: {
    AppDate,
  },
  data() {
    return {
      tiers: [],
      contrats: [],
      commande: {},
      isLoading: false,
      selectedTiers: null,
      oldTiers: null,
      search: null,
      noContrat: false,
      currentDate: format(new Date(), 'yyyy-MM-dd'),
      minReceptionDate: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
      required: [(v) => !!v || 'Champ requis'],
      nbEchRules: [
        (v) => !!v || 'Champ requis',
        (v) => (!!v && v >= 1 && v <= 100) || 'Min 1 - Max 100',
      ],
      typeAnalyse: {},
      typesAnalyses: [
        {
          code: 6,
          text: 'Contaminants alimentaires',
          hint: 'Pesticides, mycotoxines, métaux lourds,alcaloïdes, HAP, dioxines, acrylamide, OGM, MOSH…',
        },
        {
          code: 4,
          text: 'Chimie nutritionnelle',
          hint: 'Étiquetage, composition, nutriments, minéraux, acides gras…',
        },
        {
          code: 1,
          text: 'Agronomie/hydrologie',
          hint: 'Terre, matières fertilisantes, solution nutritive, reliquat, eau,couverts végétaux…',
        }
      ],
    };
  },

  computed: {
    ...mapState('data', ['listTiers', 'currentCommande', 'currentTiers']),
    ...mapState('auth', ['user', 'isLogin']),
  },

  watch: {
    currentCommande: {
      deep: true,
      handler(newVal) {
        this.commande = newVal;
        if (!this.commande.date_previs_reception) {
          this.commande.date_previs_reception = this.minReceptionDate;
        }
      },
    },

    typeAnalyse(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.commande.type_analyse = newVal.code;
      }
    },

    isLogin(newVal, oldVal) {
      if (newVal && newVal !== oldVal) {
        this.getTiers();
      }
    },
  },

  created() {
    window.addEventListener('unload', this.resetCommande);
    let cmd = this.currentCommande;
    if (cmd) {
      this.commande = cmd;
      this.initAnalyse();
      if (!this.commande.date_previs_reception) {
        this.commande.date_previs_reception = this.minReceptionDate;
      }
    }
    this.selectedTiers = this.currentTiers;
    if (this.selectedTiers) {
      env.promise.then(() => {
        this.getContrats(this.selectedTiers);
      });
    }
    if (this.isLogin) {
      env.promise.then(() => {
        this.getTiers();
      });
    }

    this.$watch(
      'selectedTiers',
      function (newVal, oldVal) {
        this.oldTiers = oldVal;
        let selectedTiers = newVal;
        if (!selectedTiers || typeof selectedTiers !== 'object') {
          this.resetCommande();
          return;
        }
        this.user.login = selectedTiers.tiers_code;
        this.user.name = selectedTiers.tiers_nom;

        if (selectedTiers.tiers_prenom) {
          this.user.name += `-${selectedTiers.tiers_prenom}`;
        }

        this.switchTiers(this.user);
        if (this.oldTiers) {
          this.resetCommande();
        }
        this.$refs['contrat'].focus();
        this.setCurrentTiers(selectedTiers);
        this.getContrats(selectedTiers);
        this.commande.type_analyse = this.typeAnalyse.code;
      },
      {
        deep: true,
      },
    );
  },

  methods: {
    ...mapMutations('loading', ['loading']),
    ...mapMutations('data', [
      'setListTiers',
      'setCurrentCommande',
      'setCurrentTiers',
    ]),
    ...mapActions('data', ['removeCurrentTiers', 'removeCurrentCommande']),
    ...mapActions('auth', ['switchTiers']),

    async next() {
      let isok = await this.saveCommande();
      if (isok) {
        this.$router.push('/documents');
      }
    },

    actionOnServiceError(error) {
      if (error.message === '401') {
        this.resetCommande();
        keycloakSrv.logout();
      } else {
        this.handleServiceError(error);
      }
    },

    initAnalyse() {
      for (let analyse of this.typesAnalyses) {
        if (analyse.code === this.commande.type_analyse) {
          this.typeAnalyse = analyse;
        }
      }
    },

    increment() {
      let val = parseInt(this.commande.echant_nombre);
      if (val || val === 0) {
        this.commande.echant_nombre = val + 1;
      }
      if (this.commande.echant_nombre > 100) {
        this.commande.echant_nombre = 100;
      }
    },

    decrement() {
      if (this.commande.echant_nombre > 1) {
        this.commande.echant_nombre -= 1;
      }
    },

    checkNbEch(val) {
      let intVal = parseInt(val);
      if (intVal < 1) {
        this.commande.echant_nombre = 1;
        this.$toast.warning(
          "Le nombre d'échantillons doit être compris entre 1 et 100",
        );
      } else if (intVal > 100) {
        this.commande.echant_nombre = 100;
        this.$toast.warning(
          "Le nombre d'échantillons doit être compris entre 1 et 100",
        );
      }
    },

    checkPrevDate(date) {
      if (!this.commande.date_creation) {
        this.commande.date_creation = format(new Date(), 'yyyy-MM-dd');
      }

      const creationDate = this.commande.date_creation;
      const creationDateToCompare = parseISO(format(new Date(creationDate), 'yyyy-MM-dd'));
      const prevDateToCompare = parseISO(format(new Date(date), 'yyyy-MM-dd'));

      if (isBefore(prevDateToCompare, creationDateToCompare)) {
        this.$toast.warning(
          'La date prévisionnelle de réception doit être égale ou postérieure à la date de création de la commande.', -1
        );
      } else {
        this.commande.date_previs_reception = date;
      }
    },

    /**
     * Filtre les tiers en recherchant par le nom, le prenom, le nom et le prenom, le code tiers
     * @param {object} item Le tiers passé par le filtre de recherche
     * @param {string} queryText Le terme recherché
     *
     */
    filterTiers(item, queryText) {
      const nom = item.tiers_nom ? item.tiers_nom.toLowerCase() : '';
      const prenom = item.tiers_prenom ? item.tiers_prenom.toLowerCase() : '';
      const code = item.tiers_code ? item.tiers_code.toLowerCase() : '';
      const searchText = queryText.toLowerCase();
      const all = nom + ' ' + prenom;

      return (
        nom.indexOf(searchText) > -1 ||
        prenom.indexOf(searchText) > -1 ||
        all.indexOf(searchText) > -1 ||
        code.indexOf(searchText) > -1
      );
    },

    resetCommande() {
      this.removeCurrentTiers();
      this.removeCurrentCommande();
      this.commande = {
        id: '',
        echant_nombre: 1,
        date_creation: '',
        date_previs_reception: format(new Date(), 'yyyy-MM-dd'),
        contrat_code: '',
        type_analyse: '',
        etat: 1,
      };
      this.contrats = [];
      this.oldTiers = null;
      this.search = null;
      this.noContrat = false;
      this.currentDate = format(new Date(), 'yyyy-MM-dd');
    },

    async getTiers() {
      if (this.listTiers && this.listTiers.length > 0) {
        this.tiers = this.listTiers;
        if (this.tiers.length === 1 && this.selectedTiers === null) {
          this.selectedTiers = this.tiers[0];
        }
        return;
      }

      this.loading(true);

      try {
        this.tiers = await searchSrv.getTiers();
        this.loading(false);
        this.$toast.success(`${this.tiers.length} tiers trouvé(s).`);
        this.setListTiers(this.tiers);
        if (this.tiers.length === 1 && this.selectedTiers === null) {
          this.selectedTiers = this.tiers[0];
        }
      } catch (error) {
        this.loading(false);
        this.actionOnServiceError(error);
      }
    },

    async getContrats(selectedTiers) {
      if (!selectedTiers) {
        return;
      }

      this.loading(true);

      try {
        this.contrats = await searchSrv.getContrats(selectedTiers.tiers_code);
        this.loading(false);
        this.$toast.success(`${this.contrats.length} contrat(s) trouvé(s).`);
        if (this.contrats.length === 1 && !this.commande.contrat_code) {
          this.commande.contrat_code = this.contrats[0].contrat_code;
        }

        this.noContrat = this.contrats.length ? false : true;
      } catch (error) {
        this.loading(false);
        this.actionOnServiceError(error);
      }
    },

    async saveCommande() {
      if (!this.selectedTiers) {
        return;
      }

      this.commande.date_previs_reception = format(
        new Date(this.commande.date_previs_reception),
        'yyyy-MM-dd HH:mm:ss',
      );

      this.loading(true);

      try {
        if (this.oldTiers) {
          await this.deleteCommande(this.oldTiers);
          this.oldTiers = null;
        }

        if (this.commande.id) {
          this.commande = await updateSrv.updateCommande(
            this.selectedTiers.tiers_code,
            this.commande,
          );
        } else {
          this.commande = await updateSrv.saveCommande(
            this.selectedTiers.tiers_code,
            this.commande,
          );
        }
        this.setCurrentCommande(this.commande);
        this.loading(false);
        return true;
      } catch (error) {
        this.loading(false);
        this.actionOnServiceError(error);
        return false;
      }
    },

    async deleteCommande(oldTiers) {
      if (!oldTiers || !this.commande.id) {
        return;
      }

      this.loading(true);

      try {
        if (this.commande.id) {
          await updateSrv.deleteCommande(oldTiers.tiers_code, this.commande);
        }
        this.loading(false);
        this.removeCurrentCommande();
        this.removeCurrentTiers();

        this.commande = {
          id: '',
          echant_nombre: 1,
          date_creation: format(new Date(), 'yyyy-MM-dd'),
          date_previs_reception: format(new Date(), 'yyyy-MM-dd'),
          contrat_code: '',
          type_analyse: '',
          etat: 1,
        };
      } catch (error) {
        this.loading(false);
        this.actionOnServiceError(error);
      }
    },
  },
};
</script>

<style scoped>
.small-screen {
  display: none;
}
.big-screen {
  display: block;
}
.pas-contrat {
  display: block;
  font-size: 1.3em;
  text-align: left;
  line-height: 1.5;
}
.label-text {
  padding: 15px 5px 5px 0px;
  vertical-align: sub;
  font-size: larger;
}
.label-text-center {
  padding: 15px 5px 5px 0px;
  text-align: center;
  font-size: larger;
}
.row-margin {
  margin: -20px -24px -40px -24px !important;
}
.lg-row-margin {
  margin: 20px -24px -40px -24px !important;
}
.row-margin-action {
  margin: -40px -16px -16px -16px !important;
}
@media screen and (min-width: 959px) {
  .row-margin {
    margin: 40px -24px -40px -24px !important;
  }
  .small-screen {
    display: none;
  }
  .big-screen {
    display: block;
  }
  .pas-contrat {
    display: block;
    font-size: 1.3em;
    text-align: left;
    line-height: 1.5;
  }
}
@media screen and (min-width: 0px) and (max-width: 959px) {
  .small-screen {
    display: block !important;
  }
  .big-screen {
    display: none !important;
  }
  .pas-contrat {
    display: block;
    font-size: 2.3em;
    text-align: center;
    line-height: 1.5;
  }
}
.row-margin-action-lg {
  margin: -16px !important;
}
.col-pad-first {
  padding-top: 24px !important;
  padding-bottom: 0px !important;
}
.col-pad {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.align-title {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.page-title {
  font-weight: 700;
  font-size: 1.4em;
  margin-bottom: 10px;
  margin-top: -10px;
}
.page-title-lg {
  text-align: left;
  font-weight: 800;
  font-size: x-large;
  margin-bottom: 20px;
  margin-top: 0px;
  margin-left: -10px;
}
.align-text {
  text-align: center;
  margin-bottom: -10px;
}
.left-part {
  display: inline-block;
  vertical-align: top;
}
.right-part {
  display: inline-block;
  width: 125px !important;
}
.f-icon {
  margin: 15px;
}
::v-deep .nb-ech-input input {
  text-align: center;
  font-size: xx-large !important;
  font-weight: bolder !important;
  text-align: center !important;
}
::v-deep .list-tiers .v-select__selections {
  font-size: 12px !important;
}
.btn-icon-right {
  position: absolute;
  right: 0px;
}
.bottom-pos {
  position: relative;
  bottom: 0;
}
.small-screen {
  height: 50px !important;
}
.footer-row {
  margin: -40px -12px 12px -12px !important;
}
</style>
